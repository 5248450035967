export type EventListener = { element: HTMLElement, type: string, listener: (e: Event) => void, options?: boolean | AddEventListenerOptions }
/**
 * @deprecated
 * Use "\@kissui/helpers/src/event-queue", this implementation has race conditions
 */
const add = (eventListeners: EventListener[] = []) => {
    eventListeners.forEach(eventListener => {
        if (!eventListener || !eventListener.element) {
            return
        }
        return eventListener.element.addEventListener(
            eventListener.type,
            eventListener.listener,
            eventListener.options
        )
    })
}
/**
 * @deprecated
 * Use "\@kissui/helpers/src/event-queue", this implementation has race conditions
 */
const remove = (eventListeners: EventListener[] = []) => {
    eventListeners.forEach(eventListener => {
        if (!eventListener || !eventListener.element) {
            return
        }
        eventListener.element.removeEventListener(eventListener.type, eventListener.listener)
    })
}

const busNamespace = 'pageBuilder'
/**
 * @deprecated
 * Use "\@kissui/helpers/src/event-queue", this implementation has race conditions
 */
export const emitCustomEvent = (eventName: string, data: unknown) => {
    const customEvent = new CustomEvent(`${busNamespace}.${eventName}`, {
        detail: data,
        bubbles: true,
        cancelable: true,
        composed: false
    })

    window.dispatchEvent(customEvent)
}
/**
 * @deprecated
 * Use "\@kissui/helpers/src/event-queue", this implementation has race conditions
 */
export default { add, emitCustomEvent, remove }
