export const buildSchema = data => {
    const { market = '', url, urlLogo, sameAs, contact = {} } = data

    let json = {
        '@context': 'http://schema.org/',
        '@type': 'Organization',
        name: `Nespresso ${market.toUpperCase()}`,
        url,
        logo: {
            url: urlLogo,
            '@context': 'http://schema.org/',
            '@type': 'ImageObject'
        },
        sameAs
    }

    if (contact.telephone) {
        json.contactPoint = {
            '@type': 'ContactPoint',
            telephone: contact.telephone,
            areaServed: market.toUpperCase()
        }
    }

    if (contact.street_address) {
        json.address = {
            '@type': 'PostalAddress',
            streetAddress: contact.street_address,
            addressLocality: contact.address_locality,
            addressRegion: market.toUpperCase(),
            postalCode: contact.postal_code,
            addressCountry: market.toUpperCase()
        }
    }

    return json
}
